<template>
  <v-card
    v-if="voucherFilename"
    class="pa-5"
  >
    <v-img :src="require(`@/assets/images/${voucherFilename}`)">
      <v-overlay
        v-if="voucherCode"
        color="transparent"
        class="voucher-transparent-overlay"
      >
        <v-card
          color="transparent"
          class="voucher-code-top"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherCode }}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          color="transparent"
          class="voucher-expired-at-top"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherExpireDate }}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          color="transparent"
          class="voucher-expired-at-side"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherExpireDate }}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          color="transparent"
          class="voucher-code-side"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherCode }}
            </div>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-img>
  </v-card>
</template>

<script>
import {
  onMounted,
  ref,
  inject,
} from '@vue/composition-api'

export default {
  props: {
    voucherCode: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')

    // Properties
    const voucherExpireDate = ref(null)
    const voucherFilename = ref(null)

    // Methods
    const fetchVoucher = () => {
      store
        .dispatch('voucherStore/fetchVoucher', { code: props.voucherCode })
        .then(response => {
          voucherExpireDate.value = response.data.data.expired_at
          voucherFilename.value = `voucher_${Math.round(response.data.data.amount)}.jpg`
        })
        .catch(error => {
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching voucher. Please refresh!')
        })
    }

    // Mounted
    onMounted(() => {
      fetchVoucher()
    })

    return {
      voucherExpireDate,
      voucherFilename,
    }
  },
}
</script>

<style>
.voucher-transparent-overlay {
  position: absolute;
  justify-content: normal;
  align-items: normal;
}
.voucher-text {
  color: black !important;
  font-size: 19px;
  font-weight: bold;
}
.voucher-code-top {
  box-shadow: none !important;
  left: 135px;
  top: -8px;
}
.voucher-expired-at-top {
  box-shadow: none !important;
  left: 240px;
  top: -53px;
}
.voucher-expired-at-side {
  box-shadow: none !important;
  left: -12px;
  top: -50px;
  transform: rotate(-90deg);
}
.voucher-code-side {
  box-shadow: none !important;
  left: -12px;
  top: 15px;
  transform: rotate(-90deg);
}
@media (max-width: 1525px) {
  .voucher-text {
    font-size: 17px;
  }
  .voucher-code-top {
    left: 105px;
    top: -8px;
  }
  .voucher-expired-at-top {
    left: 205px;
    top: -55px;
  }
  .voucher-expired-at-side {
    left: -12px;
    top: -65px;
  }
  .voucher-code-side {
    left: -12px;
    top: -15px;
  }
}
@media (max-width: 1200px) {
  .voucher-code-top {
    left: 105px;
    top: -8px;
  }
  .voucher-expired-at-top {
    left: 185px;
    top: -55px;
  }
  .voucher-expired-at-side {
    left: -12px;
    top: -65px;
  }
  .voucher-code-side {
    left: -12px;
    top: -15px;
  }
}
@media (max-width: 1024px) {
  .voucher-text {
    font-size: 18px;
  }
  .voucher-code-top {
    left: 115px;
  }
  .voucher-expired-at-top {
    left: 210px;
    top: -55px;
  }
  .voucher-expired-at-side {
    top: -60px;
  }
  .voucher-code-side {
    top: -15px;
  }
}
@media (max-width: 768px) {
  .voucher-text {
    font-size: 15px;
  }
  .voucher-code-top {
    left: 80px;
    top: -12px;
  }
  .voucher-expired-at-top {
    left: 155px;
    top: -60px;
  }
  .voucher-expired-at-side {
    left: -18px;
    top: -80px;
  }
  .voucher-code-side {
    left: -18px;
    top: -45px;
  }
}
@media (max-width: 680px) {
  .voucher-text {
    font-size: 12px;
  }
  .voucher-code-top {
    left: 70px;
    top: -12px;
  }
  .voucher-expired-at-top {
    left: 125px;
    top: -60px;
  }
  .voucher-expired-at-side {
    left: -18px;
    top: -80px;
  }
  .voucher-code-side {
    left: -18px;
    top: -45px;
  }
}
@media (max-width: 585px) {
  .voucher-text {
    font-size: 8px;
  }
  .voucher-code-top {
    left: 38px;
    top: -16px;
  }
  .voucher-expired-at-top {
    left: 85px;
    top: -58px;
  }
  .voucher-expired-at-side {
    top: -85px;
  }
  .voucher-code-side {
    top: -75px;
  }
}
@media (max-width: 418px) {
  .voucher-text {
    font-size: 7px;
  }
  .voucher-code-top {
    left: 30px;
    top: -16px;
  }
  .voucher-expired-at-top {
    left: 62px;
    top: -60px;
  }
  .voucher-expired-at-side {
    left: -18px;
    top: -90px;
  }
  .voucher-code-side {
    left: -18px;
    top: -95px;
  }
}
</style>
